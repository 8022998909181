<template>
  <div class="screen-page-container">
    <img src="https://cdn.spbcn.org/spbcnappneed/fusai_screen_logo_nomal.png" @click="refreshFn" class="left-logo" alt="">
    <img src="https://cdn.spbcn.org/spbcnappneed/bigScreen/screen_welcome_logo2.png" @click="toFullOrExit" class="right-logo" alt="">
    <div :style="pageStyle">
      <!-- welcome -->
      <div class="screen-page-welcome" v-if="status === 1 || status === 2">
        <div class="sw-main">
          <img src="https://cdn.spbcn.org/spbcnappneed/bigScreen/screen_welcome_img1.png" class="screen_welcome_img1" alt="">
          <div class="sw-main-right">
            <img src="https://cdn.spbcn.org/spbcnappneed/bigScreen/screen_welcome_img2.png" class="screen_welcome_img2" alt="">
            <div class="sw-saiqu">{{districtName}}城市复选</div>
            <img src="https://cdn.spbcn.org/spbcnappneed/bigScreen/screen_welcome_img3.png" class="screen_welcome_img3" alt="">
          </div>
        </div>
        <div class="bottom-bar"></div>
      </div>
      <!-- loading & rank -->
      <div class="screen-page-rank" v-else>
        <!-- 成绩统计中 -->
        <div class="loading" v-if="status === 3">
          <img src="https://cdn.spbcn.org/spbcnappneed/bigScreen/screen_loading.jpg" class="loading-img" alt="">
        </div>
        <!-- 排名 -->
        <div class="rank" v-else>
          <div class="rank-title">
            <div class="rank-title-level">{{ groupTypeStr }}</div>
            <img src="https://cdn.spbcn.org/spbcnappneed/bigScreen/screen_rank_title.png" class="rank-title-img" alt="">
          </div>
          <div class="rank-desc">
            <span>{{ rankInfo.submitNum }}名</span>选手提交成功
            <span>{{ rankInfo.notSubmitNum }}名</span>选手提交失败
          </div>
          <div class="rank-panel">
            <div class="rank-blank" v-for="(list, i) of rankList" :key="i">
              <div class="rank-item" :class="'rank-item-'+i+j"  v-for="(item, j) of list" :key="j">
                <div class="rank-index" :class="'rank-index-'+i+j">
                  <span v-if="!(i === 0 && (j === 0 || j === 1 || j === 2))">{{ item.ranking }}</span>
                </div>
                <div class="rank-name">{{item.name}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      pageStyle: {
        width: '100%',
        height: '100%'
      },
      code: '',
      // status 状态:1:未开始 2:比赛中 3:比赛结束 4:发布成绩
      // 1和2  跳转首页    3跳转到(成绩统计中)  4跳转到(排名)
      status: -1,
      rankList: [],
      rankInfo: {},
      size: 10, // 一列展示多少
      districtName: '',
      groupTypeStr: ''
    }
  },
  // mounted () {
  //   this.getScreenSize()
  //   window.addEventListener('resize', this.getScreenSize)
  // },
  created () {
    this.code = this.$route.query.code
    this.getStatusByCode()
  },
  // beforeDestory () {
  //   window.removeEventListener('resize', this.getScreenSize)
  // },
  methods: {
    getScreenSize () {
      const w = window.innerWidth // 屏幕宽
      const h = window.innerHeight // 屏幕高
      const standardScale = 16 / 9
      const actualScale = w / h
      const calcWidth = 16 * h / 9
      const calcHeight = 9 * w / 16
      if (actualScale < standardScale) {
        this.pageStyle.width = '100%'
        this.pageStyle.height = parseInt(calcHeight) + 'px'
      } else if (actualScale < standardScale) {
        this.pageStyle.width = parseInt(calcWidth) + 'px'
        this.pageStyle.height = '100%'
      }
    },
    getStatusByCode () {
      this.$http.get('noAuth/formMatch/codeInfo', { params: { code: this.code } }).then(res => {
        if (res.code === 200) {
          this.status = res.data.status
          this.districtName = res.data.districtName
          this.groupTypeStr = res.data.groupTypeStr
          console.log(res.data.id)
          if (this.status === 4) {
            this.getRankList(res.data.id)
          }
        }
      })
    },
    getRankList (id) {
      this.$http.get('noAuth/formMatch/winners', { params: { id } }).then(res => {
        if (res.code === 200) {
          this.rankInfo = res.data.submitData
          // if (res.data.winners.length > 50) {
          //   this.size = Math.ceil(res.data.winners.length / 5)
          // } else {
          //   this.size = 10
          // }
          this.size = 10
          this.rankList = this.sliceArray(res.data.winners)
          console.log(this.rankList, this.rankList.length)
        }
      })
    },
    sliceArray (arr) {
      const arrNum = Math.ceil(arr.length / this.size, 10) // Math.ceil()向上取整的方法，用来计算拆分后数组的长度
      let index = 0 // 定义初始索引
      let resIndex = 0 // 用来保存每次拆分的长度
      const result = []
      while (index < arrNum) {
        result[index] = arr.slice(resIndex, this.size + resIndex)
        resIndex += this.size
        index++
      }
      return result
    },
    // 左上角logo 刷新状态
    refreshFn () {
      this.getStatusByCode()
    },
    // 右上角logo全屏切换
    toFullOrExit () {
      this.isFull = !this.isFull
      if (this.isFull) {
        // this.fullImg = require('@/assets/img/efs.png')
        this.requestFullScreen()
      } else {
        // this.fullImg = require('@/assets/img/fs.png')
        this.exitFullscreen()
      }
    },
    requestFullScreen () {
      const de = document.documentElement
      if (de.requestFullscreen) {
        de.requestFullscreen()
      } else if (de.mozRequestFullScreen) {
        de.mozRequestFullScreen()
      } else if (de.webkitRequestFullScreen) {
        de.webkitRequestFullScreen()
      }
    },
    exitFullscreen () {
      const de = document
      if (de.exitFullscreen) {
        de.exitFullscreen()
      } else if (de.mozCancelFullScreen) {
        de.mozCancelFullScreen()
      } else if (de.webkitCancelFullScreen) {
        de.webkitCancelFullScreen()
      }
    }
  }
}
</script>
<style lang="less" scoped>
.screen-page-container{
  // width: 100vw;
  // height: 100vh;
  background-color: #b81c25;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.left-logo{
  position: absolute;
  left: 42px;
  top: 40px;
  height: 42px;
}
.right-logo{
  position: absolute;
  right: 42px;
  top: 30px;
  height: 60px;
}

// 欢迎首页
.screen-page-welcome{
  width: 100%;
  height: 100%;
  background-image: url(https://cdn.spbcn.org/spbcnappneed/bigScreen/screen_welcome_bg1.jpg);
  background-size: auto 92%;
  background-position: right bottom;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bottom-bar{
  width: 100vw;
  height: 30px;
  background-color: #ce954b;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}
.sw-main{
  display: flex;
  // align-items: center;
  justify-content: center;
}
.sw-saiqu{
  font-family: 'zzgflh';
  font-size: 100px;
  color: #fff;
}
.screen_welcome_img1{
  height: 540px;
}
.screen_welcome_img2{
  height: 176px;
}
.screen_welcome_img3{
  margin-left: 230px;
  height: 188px;
}

// 排名页
.screen-page-rank{
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-image: url(https://cdn.spbcn.org/spbcnappneed/bigScreen/screen_welcome_bg3.png);
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: left bottom;
}
.loading{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
.loading-img{
  height: 120px;
}
.rank-title{
  width: 540px;
  margin: 0 auto;
  padding-top: 40px;
  position: relative;
}
.rank-title-img{
  width: 100%;
}
.rank-title-level{
  font-size: 50px;
  font-family: 'zzgflh';
  color: #000;
  -webkit-text-stroke: 2px #fff;
  position: absolute;
  top: 46px;
  left: 188px;
}
.rank-desc{
  font-family: 'xique';
  font-size: 34px;
  color: #000;
  text-align: center;
  margin-top: 8px;
  span{
    color: #fff;
    margin-left: 10px;
  }
}
.rank-panel{
  width: 80%;
  // height: calc(100vh - 396px);
  height: 684px;
  overflow-x: auto;
  background: linear-gradient(#e6b173,#c58c3e);
  border: 4px solid #d88024;
  margin: 20px auto;
  display: flex;
  // justify-content: space-around;
  padding-top: 20px;
  padding-bottom: 20px;
  box-sizing: border-box;
}
.rank-blank{
  font-family: 'xique';
  font-size: 38px;
  color: #000;
  margin-left: 80px;
}
.rank-item{
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.rank-index{
  width: 40px;
  height: 40px;
  font-size: 22px;
  color: #fff;
  background-color: #000;
  border-radius: 40px;
  margin-right: 42px;
  margin-left: 8px;
  text-align: center;
  line-height: 34px;
}
.rank-name{
  width: 130px;
}
.rank-item-00{
  margin-bottom: 15px;
}
.rank-item-01{
  margin-bottom: 15px;
}
.rank-item-02{
  margin-bottom: 15px;
}
.rank-index-00 {
  width: 58px;
  height: 50px;
  background-color: transparent;
  background-image: url(https://cdn.spbcn.org/spbcnappneed/bigScreen/screen_rank_icon1.png);
  background-size: contain;
  background-position: center;
  margin-left: 0;
  margin-right: 34px;
}
.rank-index-01 {
  width: 58px;
  height: 50px;
  background-color: transparent;
  background-image: url(https://cdn.spbcn.org/spbcnappneed/bigScreen/screen_rank_icon2.png);
  background-size: contain;
  background-position: center;
  margin-left: 0;
  margin-right: 34px;
}
.rank-index-02 {
  width: 58px;
  height: 50px;
  background-color: transparent;
  background-image: url(https://cdn.spbcn.org/spbcnappneed/bigScreen/screen_rank_icon3.png);
  background-size: contain;
  background-position: center;
  margin-left: 0;
  margin-right: 34px;
}
</style>
